import React, {useContext} from 'react';
import {
    Window,
    WindowContent,
    WindowHeader,
    Button,
    Toolbar, Cutout,
} from 'react95';
import {GroppiusContext} from "../store";
import CustomWindow from "./CustomWindow";


const BirthWindow = ({onHide}) => {

    return (
        <CustomWindow title={'Bambino_Gesù.exe'} hide={onHide}>

                            <Cutout style={{padding: '1rem', background: 'white', width: '550px'}}>
                                <p>

                                    Gi&agrave; l&#39;incipit di tal novella<br/>
                                    &egrave; a dir poco stranitella<br/>
                                    che l&#39; eroe la luce vide<br/>
                                    con la neve in quel di aprile.<br/>
                                    Fin da subito fu chiaro<br/>
                                    che l&#39;agir per lui era affare raro.<br/>
                                    Alla scuola dell&#39;infanzia<br/>
                                    Lui andava con costanza<br/>
                                    Ma con le mani non gli piacque lavorare<br/>
                                    E per tutto lui voleva disputare.<br/>
                                    Pasta e fumetto<br/>
                                    ecco il duo perfetto,<br/>
                                    e lo scibile paperesco<br/>
                                    lui impara lesto lesto.<br/>
                                    Paperon de Paperoni<br/>
                                    lo solletica con i suoi soldoni<br/>
                                    e lezioni sul fumetto<br/>
                                    lui rilascia presto presto.<br/>
                                    Anche negli anni della scuola piccina<br/>
                                    l&rsquo;importante per lui &egrave; far riposare la testolina<br/>
                                    soprattutto di mattina.<br/>
                                    Alla mamma spazientita<br/>
                                    rispondeva devo farmi una dormita.<br/>
                                    Mentre gli anni se ne vanno<br/>
                                    Jacopo non si fa prendere dall&#39;affanno:<br/>
                                    la corriera per Lonigo se ne va<br/>
                                    be&#39; lui tranquillo a piedi sta.<br/>
                                    Alla madre incazzata<br/>
                                    mostra un viso da patata<br/>
                                    quel Liceo l&igrave; a Lonigo<br/>
                                    va spostato a stretto giro.
                                </p>
                            </Cutout>
        </CustomWindow>
    );
}


export default BirthWindow;
