import React from 'react';
import {
    Button,
} from 'react95';

import CustomWindow from "./CustomWindow";

const galleryDir = '../assets/gallery/'

const Gallery = ({onHide}) => {
    const [image, setImage] = React.useState('IMG_2554.JPG');

    function loadRandom () {
    }

    return (
        <CustomWindow title={'Galleria'} hide={onHide}>
            <div>
                <Button size='lg' onClick={()=>loadRandom()}>Carica Immagine</Button>
                <br/>
                <img
                    alt="img"
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                    src={galleryDir + image}
                />
            </div>
        </CustomWindow>
    );
}


export default Gallery;
