import React, {useState, useContext, useEffect} from 'react';
import {GroppiusContext} from '../store';
import {Button, List, ListItem, Divider, Toolbar, TextField, AppBar} from 'react95';
import ClickAwayListener from 'react-click-away-listener';
import logo from "../assets/logo.png";

const Menu = ({search, setSearch, onShow}) => {
    const [state, dispatch] = useContext(GroppiusContext);
    const [startupSound, setStartupSound] = useState(null);

    useEffect(() => {
        const ss = localStorage.getItem('startup_sound');
        if (ss === null) {
            localStorage.setItem('startup_sound', true);
            setStartupSound(true);
        } else {
            setStartupSound(ss === 'true');
        }
    }, []);

    const _handleClick = () => {
        dispatch({type: 'SET_MENU', payload: !state.menu});
    };

    const _handleClose = () => {
        dispatch({type: 'SET_MENU', payload: false});
    };

    const _handleStartupSound = () => {
        const ss = localStorage.getItem('startup_sound');
        if (ss === 'true') {
            localStorage.setItem('startup_sound', false);
            setStartupSound(false);
        } else {
            localStorage.setItem('startup_sound', true);
            setStartupSound(true);
        }
    };

    const _handleListItemClick = name => {

        switch (name) {
            case 'bambole':
                onShow(name)
                break
            case 'lavoro':
                onShow(name)
                break
        }
    };

    const _handleClickAway = () => {
        if (state.menu) {
            dispatch({type: 'SET_MENU', payload: false});
        }
    };

    return (
        <AppBar style={{ zIndex: 3 }}>
            <Toolbar style={{justifyContent: 'space-between'}}>
                <div style={{position: 'relative', display: 'inline-block'}}>
                    <ClickAwayListener onClickAway={_handleClickAway}>
                        {state.menu && (
                            <List
                                style={{position: 'absolute', left: '0', top: '100%'}}
                                onClick={_handleClose}
                            >
                                <ListItem onClick={() => _handleListItemClick('bambole')}>
                                    <span
                                        role='img'
                                        aria-label='👩‍🍼'
                                        style={{width: 22, marginRight: 8}}
                                    >
                                      🍑
                                    </span>
                                    Regalo laurea
                                </ListItem>
                                <ListItem onClick={() => _handleListItemClick('lavoro')}>
                                    <span
                                        role='img'
                                        aria-label='👩‍🍼'
                                        style={{width: 22, marginRight: 8}}
                                    >
                                      👷
                                    </span>
                                    Lavoro
                                </ListItem>
                                <Divider/>
                                <ListItem onClick={_handleStartupSound}>
                                    Effetti Sonori: {startupSound ? 'On' : 'Off'}
                                </ListItem>
                            </List>
                        )}
                        <Button
                            onClick={_handleClick}
                            active={state.menu}
                            style={{fontWeight: 'bold', marginRight: 6}}
                        >
                            <img
                                src={logo}
                                alt="startLogo"
                                style={{marginLeft: -2, marginRight: 5, width: 20}}
                            />
                            Groppius 95
                        </Button>
                    </ClickAwayListener>
                </div>

                <TextField
                    placeholder='Cerca...'
                    width={150}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </Toolbar>
        </AppBar>
    );
};

export default Menu;
