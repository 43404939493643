import React from 'react';
import {
    Window,
    WindowContent,
    WindowHeader,
    Button,
    Toolbar,
} from 'react95';

export default class CustomWindow extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        const _handleClose = () => {
            this.props.hide('finestra')
        };

        return (
            <div>
                {
                    < Window
                        style={
                            {
                                width: 600,
                                maxWidth: '94%',
                                maxHeight: '100%',
                                minHeight: 450,
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2
                            }
                        }
                    >
                        <WindowHeader className="flex items-center justify-between">
                            <span>{this.props.title}</span>
                            <Button onClick={_handleClose}>
                    <span style={{fontWeight: 'bold', transform: 'translateY(-1px)'}}>
                    x
                    </span>
                            </Button>
                        </WindowHeader>
                        <Toolbar>
                            <Button disabled={true} variant="menu" size="sm">
                                File
                            </Button>
                            <Button onClick={_handleClose} variant="menu" size="sm">
                                Esci
                            </Button>
                        </Toolbar>
                        <WindowContent>
                            <>
                                {this.props.children}
                            </>
                        </WindowContent>
                    </Window>
                }
            </div>
        );
    }
}