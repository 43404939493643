import React, {useState} from 'react';
import {
    Window,
    WindowContent,
    WindowHeader,
    Button,
    Toolbar,
} from 'react95';
import CustomWindow from "./CustomWindow";
import PasswordLock from "./PasswordLock";
import Karaoke from "./Karaoke";

export default class PasswordWindow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            guessed: false
        }
    }
    render() {
        const handleGuessed = () => {
            this.setState({
                guessed: true
            })
        };

        return (
            <CustomWindow hide={this.props.onHide} title={this.props.title} >
                {this.state.guessed? this.props.children : <PasswordLock password={this.props.password} guessed={handleGuessed} hint={this.props.hint}/>}
            </CustomWindow>
        );
    }
}