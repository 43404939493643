import React, {useState} from 'react';
import {
    Button, Fieldset, TextField,
} from 'react95';

export default class PasswordLock extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {

        const handleChange = e => {
            if (this.props.password.toLowerCase() === e.target.value.toLowerCase()) {
                this.props.guessed()
            }
        };

        return (
            <div>
                <div>
                    <Fieldset label='Suggerimento'>
                        <span role='img' aria-label='😍'>
                          {this.props.hint}
                        </span>
                    </Fieldset>
                    <br />
                    <TextField
                        placeholder='Inserire Password...'
                        onChange={handleChange}
                        fullWidth
                    />
                </div>
            </div>
    );
    }
    }