import React, {useEffect, useState} from 'react';
import FilterResults from 'react-filter-search';
import './App.css';
import {createGlobalStyle, ThemeProvider} from 'styled-components';

import {
    styleReset,
} from 'react95';
// pick a theme of your choice
import original from "react95/dist/themes/water";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";

import Icon from "./components/Icon";
import Groppius from "./store";
import Menu from "./components/Menu";
import BirthWindow from "./components/BirthWindow";
import ImageViewer from "./components/ImageViewer";
import VideoPlayer from "./components/VideoPlayer";
import Karaoke from "./components/Karaoke";
import PasswordWindow from "./components/PasswordWindow";
import Gallery from "./components/Gallery";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  ${styleReset}
`;

function App() {

    const [icons, setIcons] = useState([]);
    const [search, setSearch] = useState('');
    const [error, setError] = useState(false);

    const [windowTitle, setWindowTitle] = useState('');

    const [galleryVisible, setGalleryVisible] = useState(false);

    const [imageVisible, setImageVisible] = useState(false);
    const [image, setImage] = useState('');

    const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);
    const [video, setVideo] = useState('');
    const [videoPlayerWidth, setVideoPlayerWidth] = useState(450);
    const [videoPlayerHeight, setVideoPlayerHeight] = useState(450);

    const [karaokeVisible, setKaraokeVisible] = useState(false);
    const [karaokeText, setKaraokeText] = useState('');

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('1234');
    const [hint, setHint] = useState('La combinazione che un deficiente userebbe per la sua valigia');

    useEffect(() => {

        const testIcons = [
            {
                id: 1,
                icon: 'logo.png',
                text: 'foto compromettente 01.jpg'
            },
            {
                id: 2,
                icon: 'calcutta.jpg',
                text: 'Vacanze Calcutta'
            },
            {
                id: 3,
                icon: 'faccia_02.png',
                text: 'Karaoke'
            },
            {
                id: 4,
                icon: 'faccia_01.png',
                text: 'foto compromettente 02.jpg'
            },
            {
                id: 5,
                icon: 'putin.png',
                text: 'slav.jpg'
            },
            {
                id: 6,
                icon: 'shining.png',
                text: 'The Shining.mp4'
            },
            {
                id: 7,
                icon: 'lock.svg',
                text: 'mandragola.zip'
            },
            {
                id: 8,
                icon: 'faccia_03.png',
                text: 'los pollos'
            },
            {
                id: 9,
                icon: 'faccia_04.png',
                text: 'non_ghe_campo.png'
            },
            {
                id: 10,
                icon: 'faccia_05.png',
                text: 'si_ma.jpg'
            },
            {
                id: 11,
                icon: 'faccia_06.png',
                text: 'cippecciop.jpg'
            },
            {
                id: 12,
                icon: 'faccia_07.png',
                text: 'riflessioni.jpg'
            },
            {
                id: 13,
                icon: 'faccia_08.png',
                text: 'kebbbab.jpg'
            },
            {
                id: 14,
                icon: 'faccia_09.png',
                text: 'gang.jpg'
            },
            {
                id: 15,
                icon: 'faccia_10.png',
                text: 'adrianaa.jpg'
            },
            {
                id: 16,
                icon: 'faccia_11.png',
                text: 'new_year.jpg'
            },
            {
                id: 17,
                icon: 'cash_icon.svg',
                text: 'cash.jpg'
            },
            {
                id: 18,
                icon: 'salvini.png',
                text: 'capitano.jpg'
            },
            {
                id: 19,
                icon: 'thps.png',
                text: "Tony Hawk's Pro Skater 4"
            },
            {
                id: 20,
                icon: 'sirenetta.png',
                text: 'La Sirenetta (1989).mov'
            },
            {
                id: 21,
                icon: 'croce.png',
                text: 'ti ricordiamo cosi.jpg'
            },
            {
                id: 22,
                icon: 'candy.jpg',
                text: 'Candy Crush'
            },
            {
                id: 23,
                icon: 'volto.svg',
                text: 'pronto.png'
            },
            {
                id: 24,
                icon: 'dracula3.png',
                text: 'Dracula 3.exe'
            },
            {
                id: 25,
                icon: 'gender.svg',
                text: 'prima volta.jpg'
            },
            {
                id: 26,
                icon: 'dance.svg',
                text: 'bailar.jpg'
            },
            {
                id: 27,
                icon: 'armageddon.jfif',
                text: 'Armageddon.avi'
            },
            {
                id: 28,
                icon: 'water-bubbles.svg',
                text: 'bollicine.png'
            }
        ]

        try {
            setIcons(testIcons);
        } catch (e) {
            setError(true);
        }

    }, []);

    const setSelected = id => {
        const data = icons.map(i => {
            return {
                ...i,
                selected: false,
            };
        });
        const idx = data.findIndex(i => i.id === +id);
        data[idx].selected = true;
        setIcons(data);
    };

    function handleIcon(i) {
        switch (i) {
            case 1:
                setImage('porno_01.jpg')
                setWindowTitle('Magic 🕺 Jacopo')
                showWindow('visualizzatoreImmagini');
                break;
            case 2:
                setVideo('calcutta_video.mp4')
                setWindowTitle('Il Virtuoso')
                setVideoPlayerWidth(500)
                setVideoPlayerHeight(700)
                showWindow('lettoreVideo');
                break;
            case 3:
                setVideo('https://www.youtube.com/watch?v=Rk2IJB6pYKY')
                setWindowTitle('Calcutta | Milano')
                setKaraokeText('milano')
                showWindow('karaoke');
                break;
            case 4:
                setImage('porno_02.jpg')
                setWindowTitle('🤘😈🤘')
                showWindow('visualizzatoreImmagini');
                break;
            case 5:
                setImage('slav.jpg')
                setWindowTitle('slav.jpg')
                showWindow('visualizzatoreImmagini');
                break;
            case 6:
                setVideo('ascensore.mp4')
                setWindowTitle('The.Shining.1980.US.1080p.BluRay.H264.AAC-RARBG')
                setVideoPlayerWidth(500)
                setVideoPlayerHeight(700)
                showWindow('lettoreVideo');
                break;
            case 7:
                setWindowTitle('Il fico sacro')
                setPassword('i nani')
                setHint('Li avete visti -- Sulle rive del fiume')
                setImage('spasmo.jpeg')
                showWindow('password');
                break;
            case 8:
                setImage('el_pojo.jpg')
                setWindowTitle('Los Pollos Loco')
                showWindow('visualizzatoreImmagini');
                break;
            case 9:
                setImage('non_ghe_campo.jpg')
                setWindowTitle("Non gh'è campo")
                showWindow('visualizzatoreImmagini');
                break;
            case 10:
                setImage('si_ma.jpg')
                setWindowTitle('Si ma insomma')
                showWindow('visualizzatoreImmagini');
                break;
            case 11:
                setImage('scoiattolo.jpg')
                setWindowTitle('Cippecciop de noartri')
                showWindow('visualizzatoreImmagini');
                break;
            case 12:
                setImage('riflessioni.jpg')
                setWindowTitle('Lato migliore?')
                showWindow('visualizzatoreImmagini');
                break;
            case 13:
                setImage('kebab.jpg')
                setWindowTitle('Kesh Maioness?')
                showWindow('visualizzatoreImmagini');
                break;
            case 14:
                setImage('fumo.jpeg')
                setWindowTitle('Dark Groppo Gang')
                showWindow('visualizzatoreImmagini');
                break;
            case 15:
                setImage('adriana.JPG')
                setWindowTitle('Maccio man')
                showWindow('visualizzatoreImmagini');
                break;
            case 16:
                setImage('barba.jpeg')
                setWindowTitle('Anno nuovo sborricchio nuovo')
                showWindow('visualizzatoreImmagini');
                break;
            case 17:
                setImage('cash.jpg')
                setWindowTitle('Ogni minuto nel mondo 5 bambini muoiono di fame')
                showWindow('visualizzatoreImmagini');
                break;
            case 18:
                setImage('findus.jpg')
                setWindowTitle('Findus')
                showWindow('visualizzatoreImmagini');
                break;
            case 19:
                setImage('skater.jpeg')
                setWindowTitle("Jacopo Groppo's Pro Skater 4" )
                showWindow('visualizzatoreImmagini');
                break;
            case 20:
                setImage('sirenetto.jpeg')
                setWindowTitle("In fondo al bar" )
                showWindow('visualizzatoreImmagini');
                break;
            case 21:
                setImage('rip.JPG')
                setWindowTitle("Salutava sempre" )
                showWindow('visualizzatoreImmagini');
                break;
            case 22:
                setImage('gioco.jpeg')
                setWindowTitle("Livello 7842378234678234" )
                showWindow('visualizzatoreImmagini');
                break;
            case 23:
                setImage('riccioli.jpeg')
                setWindowTitle("È la schiuma" )
                showWindow('visualizzatoreImmagini');
                break;
            case 24:
                setImage('vampiro.jpg')
                setWindowTitle("Dracula 3 la vendetta" )
                showWindow('visualizzatoreImmagini');
                break;
            case 25:
                setImage('manichino.jpg')
                setWindowTitle("Non si scorda mai" )
                showWindow('visualizzatoreImmagini');
                break;
            case 26:
                setImage('ballare.jpeg')
                setWindowTitle("Ma questo è il mio pezzo!" )
                showWindow('visualizzatoreImmagini');
                break;
            case 27:
                setImage('avanzano.jpeg')
                setWindowTitle("The Crew" )
                showWindow('visualizzatoreImmagini');
                break;
            case 28:
                setImage('bolle.png')
                setWindowTitle("Non era sapone" )
                showWindow('visualizzatoreImmagini');
                break;
            default: console.log('Click su icona non gestita -> id: ' + i)

        }
    }

    function showWindow (_window) {
        switch (_window) {
            case 'bambole':
                const win = window.open('https://www.amazon.it/Bambole-Gonfiabili/b?ie=UTF8&node=4327180031', '_blank');
                win.focus();
                break;
            case 'lavoro':
                const win2 = window.open('https://it.indeed.com/offerte-lavoro?q=muratore&l=montecchio+maggiore', '_blank');
                win2.focus();
                break;
            case 'visualizzatoreImmagini':
                setGalleryVisible(false)
                setVideoPlayerVisible(false)
                setKaraokeVisible(false)
                setPasswordVisible(false)
                setImageVisible(true)
                break;
            case 'lettoreVideo':
                setGalleryVisible(false)
                setImageVisible(false);
                setKaraokeVisible(false)
                setPasswordVisible(false)
                setVideoPlayerVisible(true)
                break;
            case 'karaoke':
                setGalleryVisible(false)
                setImageVisible(false);
                setVideoPlayerVisible(false)
                setPasswordVisible(false)
                setKaraokeVisible(true)
                break;
            case 'password':
                setGalleryVisible(false)
                setImageVisible(false);
                setVideoPlayerVisible(false)
                setKaraokeVisible(false)
                setPasswordVisible(true)
                break;
           default:
               console.log('Richiesta apertura finestra sconosciuta: '+ _window)
               break;
        }
    }

    function hideWindow (window) {
        //todo: se non mi serve riconoscere le singole finestre posso chiudere tutto senza contorllare window, volendo
        switch (window) {
            case 'finestra':
                // tutte le finestre che usano customWindow vengono chiuse qui
                setGalleryVisible(false);
                setPasswordVisible(false)
                break;
            case 'visualizzatoreImmagini':
                setImageVisible(false)
                break;
            case 'lettoreVideo':
                setVideoPlayerVisible(false)
                break;
            case 'karaoke':
                setKaraokeVisible(false)
                break;
            default:
                console.log('Richiesta chiusura finestra sconosciuta: '+ window)
                break;
        }
    }


    return (
        <Groppius>
            <GlobalStyles/>
            <ThemeProvider theme={original}>
                <nav>
                    <Menu search={search} setSearch={setSearch} onShow={showWindow}/>
                </nav>
                <main>
                <div className="container pt4">
                    {imageVisible? <ImageViewer onHide={hideWindow} image={image} title={windowTitle}/> : null}
                    {videoPlayerVisible? <VideoPlayer onHide={hideWindow} video={video} title={windowTitle} playerWidth={videoPlayerWidth} playerHeight={videoPlayerHeight}/> : null}
                    {karaokeVisible? <Karaoke onHide={hideWindow} video={video} title={windowTitle} testo={karaokeText}/> : null}
                    {passwordVisible? <PasswordWindow password={password} onHide={hideWindow} title={windowTitle} hint={hint}>
                        <img
                        alt="img"
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                        src={require('./assets/' + image)}
                        />
                    </PasswordWindow> : null}
                    <div className="clearfix mxn1">
                        <FilterResults
                            value={search}
                            data={icons}
                            renderResults={results => (
                                <>
                                    {results.map(i => (
                                        <div
                                            key={i.id}
                                            className="col col-6 sm-col-3 md-col-2 px1 mb2"
                                        >
                                            <Icon icon={i} listener={handleIcon} />
                                        </div>
                                    ))}
                                </>
                            )}
                        />
                    </div>
                </div>
            </main>
            </ThemeProvider>
        </Groppius>
    );
}

export default App;

