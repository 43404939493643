import React, {useContext} from 'react';
import {
    Window,
    WindowContent,
    WindowHeader,
    Button,
    Toolbar, Cutout,
} from 'react95';
import {GroppiusContext} from "../store";


const ImageViewer = ({onHide, image, title}) => {

    const _handleClose = () => {
        onHide('visualizzatoreImmagini')
    };

    return (
        <div>
            {
                < Window
                    style={
                        {
                            maxWidth: '100%',
                            maxHeight: '100%',
                            minWidth: '300px',
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }
                    }
                >
                    <WindowHeader className="flex items-center justify-between">
                        <span>{title? title: 'Visualizzatore Immagini'}</span>
                        <Button onClick={_handleClose}>
                    <span style={{fontWeight: 'bold', transform: 'translateY(-1px)'}}>
                    x
                    </span>
                        </Button>
                    </WindowHeader>
                    <WindowContent>
                        <>
                            <img
                                alt="img"
                                style={{

                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    maxWidth: '100%',
                                    height: 'auto'
                                }}
                                src={require('../assets/' + image)}
                            />
                            </>
                    </WindowContent>
                </Window>
            }
        </div>
    );
}


export default ImageViewer;
