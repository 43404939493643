import React, {createContext, useReducer} from "react";

export const GroppiusContext = createContext({});

const initialState = {
    menu: false,
    windowModal: false,
    activeWindow: '',
    hideWindowModalButton: true,
};
function reducer(state, action) {
    switch (action.type) {
        case 'SET_MENU':
            return {
                ...state,
                menu: action.payload,
            };
        case 'SET_WINDOW_MODAL':
            return {
                ...state,
                windowModal: action.payload,
            };
        case 'SET_HIDE_WINDOW_MODAL_BUTTON':
            return {
                ...state,
                hideWindowModalButton: action.payload,
            };
        case 'SET_ACTIVE_WINDOW':
            return {
                ...state,
                activeWindow: action.payload,
            };
        default:
            return state;
    }
}

const Groppius = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <GroppiusContext.Provider value={[state, dispatch]}>
            {children}
        </GroppiusContext.Provider>
    );
};

export default Groppius;
