import React from 'react';

import ReactPlayer from 'react-player/file'
import {Button, Window, WindowContent, WindowHeader} from "react95";

const VideoPlayer = ({onHide, video, title, playerWidth, playerHeight}) => {

    const _handleClose = () => {
        onHide('lettoreVideo')
    };

    return (
    <div>
        {
            < Window
                style={
                    {
                        maxWidth: '94%',
                        maxHeight: '94%',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                    }
                }
            >
                <WindowHeader className="flex items-center justify-between">
                    <span>{title? title: 'Window Media Player'}</span>
                    <Button onClick={_handleClose}>
                    <span style={{fontWeight: 'bold', transform: 'translateY(-1px)'}}>
                    x
                    </span>
                    </Button>
                </WindowHeader>
                <WindowContent>
                    <>
                        <ReactPlayer
                            url={require('../assets/' + video)}
                            controls
                            pip={false}
                            width={playerWidth ? playerWidth: 450}
                            height={playerHeight ? playerHeight: 450}
                            style={{

                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                maxWidth: '100%',
                                height: 'auto'
                            }}
                        />
                    </>
                </WindowContent>
            </Window>
        }
    </div>
    );
}

export default VideoPlayer;
