import React from 'react';

import ReactPlayer from 'react-player'
import {Button, Panel, TextField, Tooltip, Window, WindowContent, WindowHeader} from "react95";
import lyrics from "../assets/songsLyrics";

const Karaoke = ({onHide, video, title, playerWidth, playerHeight, testo}) => {

    const _handleClose = () => {
        onHide('karaoke')
    };

    return (
        <div>
            {
                < Window
                    style={
                        {
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '94%',
                            maxHeight: '94%',
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2,
                        }
                    }
                >
                    <WindowHeader className="flex items-center justify-between">
                        <span>{title ? title : 'Karaoke'}</span>
                        <Button onClick={_handleClose}>
                    <span style={{fontWeight: 'bold', transform: 'translateY(-1px)'}}>
                    x
                    </span>
                        </Button>
                    </WindowHeader>
                    <WindowContent>
                        <>
                            <Panel
                                variant='outside'
                                shadow
                                style={{
                                    padding: '0.5rem',
                                    lineHeight: '1.5',
                                    width: 'auto',
                                    height: 'auto',
                                }}
                            >

                                <ReactPlayer
                                    url={video}
                                    controls
                                    pip={false}
                                    style={{
                                        display: "inline-block"
                                    }}
                                />
                                    <TextField
                                        multiline
                                        defaultValue={lyrics[testo]}
                                        style={{
                                            display: "inline-block",
                                            verticalAlign:'top',
                                            width: '400px',
                                            height: '360px',
                                            padding: '5px',
                                            marginLeft: '10px'
                                        }} />
                            </Panel>
                        </>
                    </WindowContent>
                </Window>
            }
        </div>
    );
}

export default Karaoke;
