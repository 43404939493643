import React, {useContext} from 'react';
import VisibilitySensor from 'react-visibility-sensor';


class Icon extends React.Component {

    render() {
        return (
            <div
                tabIndex="0"
                className="pointer flex flex-column justify-center items-center"
                style={{outline: 'none'}}
                onClick={() => this.props.listener(this.props.icon.id)}
            >
                <VisibilitySensor>
                    <img
                        alt="placeholder"
                        style={{ height: 50 }}
                        src={require('../assets/' + this.props.icon.icon)}
                    />
                </VisibilitySensor>
                <span
                    className="text-underline"
                    style={{
                        color: '#FFFFFF',
                        userSelect: 'none',
                        padding: 2,
                        textDecoration: 'underline',
                    }}
                >
                    {this.props.icon.text}
                </span>
            </div>
        );
    }
}

export default Icon;